import { Grid } from "@mui/material";

import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { ShipmentItem } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { getBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import {
  getCustomRound,
  toKg,
  toThousandUnitFormat,
} from "@sellernote/_shared/src/utils/common/number";
import { getSupplyValueInUnit } from "@sellernote/_shared/src/utils/forwarding/bid";

import SADSTable, { TableBodyRow, TableHeadCell } from "../../../SADSTable";
import TotalTable, { TableBodyTotalRow } from "../../TotalTable";

type CellKey =
  | "NO"
  | "품명"
  | "포장유형"
  | "수량"
  | "물동량"
  | "부피"
  | "중량"
  | "위험물"
  | "냉동/냉장여부"
  | "2단적재";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "NO", label: "NO", width: 100 },
  { cellKey: "품명", label: "품명", width: 180 },
  { cellKey: "포장유형", label: "포장유형", width: 120 },
  { cellKey: "수량", label: "수량", align: "right", width: 120 },
  { cellKey: "물동량", label: "물동량", align: "right", width: 120 },
  { cellKey: "부피", label: "부피", align: "right", width: 120 },
  { cellKey: "중량", label: "중량", align: "right", width: 120 },
  { cellKey: "위험물", label: "위험물", align: "right", width: 120 },
  {
    cellKey: "냉동/냉장여부",
    label: "냉동/냉장여부",
    align: "right",
    width: 120,
  },
  { cellKey: "2단적재", label: "2단적재", align: "right", width: 120 },
];

type RequiredTotalKey = "총계" | "물동량" | "부피" | "중량" | "empty";
type OptionalTotalKey = "수량";

type ItemList = {
  item: Pick<
    ShipmentItem,
    | "id"
    | "name"
    | "packingType"
    | "quantity"
    | "horizontal"
    | "vertical"
    | "height"
    | "volumeUnit"
    | "weight"
    | "weightUnit"
    | "isDangerous"
    | "canStack"
    | "cbm"
  > &
    Partial<Pick<ShipmentItem, "needRefrigeration">>;
};

function LCLAndAIRItemList({
  itemGroupList,
  supply,
  freightType,
}: {
  itemGroupList: ItemList[];
  supply: number;
  freightType: FreightType;
}) {
  const bodyRowList: TableBodyRow<CellKey>[] = (itemGroupList || []).map(
    ({ item }) => {
      return {
        rowKey: item.id,
        NO: { value: item.id },
        품명: { value: item.name },
        포장유형: { value: item.packingType },
        수량: { value: toThousandUnitFormat(item.quantity) },
        물동량: { value: "-" }, // 총 물동량만 표기
        부피: {
          value: item.horizontal
            ? `${item.cbm}CBM (${item.horizontal}x${item.vertical}x${item.height})${item.volumeUnit}`
            : `${item.cbm}CBM`,
        },
        중량: {
          value: (
            <>
              {item.weight}
              {item.weightUnit}
            </>
          ),
        },

        위험물: {
          value: getBooleanValueToKr({
            value: item.isDangerous,
            textType: "포함/미포함",
          }),
        },
        "냉동/냉장여부": {
          value: getBooleanValueToKr({
            value: item.needRefrigeration,
            textType: "예/아니오",
          }),
        },
        "2단적재": {
          value: getBooleanValueToKr({
            value: item.canStack,
            textType: "가능/불가",
          }),
        },
      };
    }
  );

  const totalCBM = itemGroupList.reduce(
    (acc, cur) => acc + (cur.item.cbm || 0),
    0
  );

  const totalWeight = itemGroupList.reduce((acc, cur) => {
    if (cur.item.weightUnit === "NONE") {
      return acc;
    }

    return (
      acc +
      toKg(cur.item.weight || 0, cur.item.weightUnit || "kg") *
        (cur.item.quantity || 0)
    );
  }, 0);

  const isSingleRow = itemGroupList.length === 1;

  const totalRow = {
    총계: {
      value: "총계",
      isLabel: true,
      colSpan: isSingleRow ? 4 : 3,
      align: "center",
    },
    ...(isSingleRow
      ? {}
      : {
          수량: {
            value: `${toThousandUnitFormat(
              itemGroupList?.reduce(
                (acc, cur) => acc + (cur.item.quantity || 0),
                0
              )
            )}`,
            align: "right",
          },
        }),
    물동량: {
      value: `${getSupplyValueInUnit(freightType, supply)}`,
      align: "right",
    },
    부피: {
      value: `${getCustomRound(totalCBM, 3)}CBM`,
      align: "right",
    },
    중량: {
      value: `${totalWeight}KG`,
      align: "right",
    },
    empty: { value: "", colSpan: 3 },
  } as TableBodyTotalRow<RequiredTotalKey | OptionalTotalKey>;

  return (
    <Grid direction="column" spacing={2}>
      <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />

      <TotalTable headCellList={headCellList} totalRow={totalRow} />
    </Grid>
  );
}

export default LCLAndAIRItemList;
