import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { ShipmentCreateContainersInfo } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import { BidProductInfo } from "@sellernote/_shared/src/types/forwarding/bid";

import ItemListForFCL from "./ItemListForFCL";
import ItemListForLCLAndAIR from "./ItemListForLCLAndAIR";

function ItemList({
  freightType,
  containersInfo,
  productsInfo,
}: {
  freightType: FreightType;
  containersInfo: ShipmentCreateContainersInfo[];
  productsInfo: BidProductInfo[];
}) {
  if (freightType === "FCL") {
    return <ItemListForFCL containersInfo={containersInfo} />;
  }

  if (freightType === "LCL" || freightType === "AIR") {
    return (
      <ItemListForLCLAndAIR
        productsInfo={productsInfo}
        freightType={freightType}
      />
    );
  }

  return null;
}

export default ItemList;
