import { Grid } from "@mui/material";

import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { ShipmentItem } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { getBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import { getSupplyValueInUnit } from "@sellernote/_shared/src/utils/forwarding/bid";

import SADSTable, { TableBodyRow, TableHeadCell } from "../../../SADSTable";

type CellKey =
  | "NO"
  | "품명"
  | "포장유형"
  | "물동량"
  | "CBM"
  | "중량"
  | "위험물"
  | "냉동/냉장여부"
  | "2단적재";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "NO", label: "NO", width: 100 },
  { cellKey: "품명", label: "품명", width: 250 },
  { cellKey: "포장유형", label: "포장유형", width: 130 },
  { cellKey: "물동량", label: "물동량", align: "right", width: 150 },
  { cellKey: "CBM", label: "CBM", align: "right", width: 150 },
  { cellKey: "중량", label: "중량", align: "right", width: 150 },
  { cellKey: "위험물", label: "위험물", width: 100 },
  { cellKey: "냉동/냉장여부", label: "냉동/냉장여부", width: 100 },
  { cellKey: "2단적재", label: "2단적재", width: 100 },
];

type ItemList = {
  item: Pick<
    ShipmentItem,
    | "id"
    | "name"
    | "packingType"
    | "quantity"
    | "cbm"
    | "weight"
    | "weightUnit"
    | "isDangerous"
    | "canStack"
  > &
    Partial<Pick<ShipmentItem, "needRefrigeration">>;
};

function LCLAndAIRTotalList({
  itemGroupList,
  supply,
  freightType,
}: {
  itemGroupList: ItemList[];
  supply: number;
  freightType: FreightType;
}) {
  const bodyRowList: TableBodyRow<CellKey>[] = (itemGroupList || []).map(
    ({ item }) => {
      return {
        rowKey: item.id,
        NO: { value: item.id },
        품명: { value: item.name },
        포장유형: { value: item.packingType },
        물동량: {
          value: getSupplyValueInUnit(freightType, supply),
        },
        CBM: {
          value: `${item.cbm}CBM`,
        },
        중량: {
          value: (
            <>
              {item.weight}
              {item.weightUnit}
            </>
          ),
        },
        위험물: {
          value: getBooleanValueToKr({
            value: item.isDangerous,
            textType: "포함/미포함",
          }),
        },
        "냉동/냉장여부": {
          value: getBooleanValueToKr({
            value: item.needRefrigeration,
            textType: "예/아니오",
          }),
        },
        "2단적재": {
          value: getBooleanValueToKr({
            value: item.canStack,
            textType: "가능/불가",
          }),
        },
      };
    }
  );

  return (
    <Grid direction="column" spacing={2}>
      <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
    </Grid>
  );
}

export default LCLAndAIRTotalList;
